import { ApiStore } from './Global/ApiStore';
import { SnackbarStore } from './SnackbarStore';
import { AuthStore } from './AuthStore';
import { RouterStore } from './RouterStore';
import { RootStore } from './StoreManager';
import { makeAutoObservable, observable, runInAction, set } from 'mobx';
import { Code, getPropertyByEntity, RoleEmployee, TabNames } from './EmployeesMarginalityStore';
import { AppUserGroupCodes } from '../types/AppUserGroup';
import { AxiosResponse } from 'axios';
import {
  Bill,
  BillShipmentsState,
  BillSource,
  BillsShipmentStatesResponse,
  BillsSourcesResponse,
  BillsStatesResponse,
  BillState,
  CustomerAsFilter,
  DealsStatesResponse,
  DealState,
  DocumentApprovalState,
  DocumentsApprovalStatesResponse,
  PaymentState,
  PaymentStatesResponse,
  SaleShipment,
  SaleShipmentType,
  ShipmentsTypesResponse,
  StatReferencesResponse,
  StatSaleFunnelDocsRequestDocFilters,
  StatSaleFunnelDocsRequestDocFiltersBill,
  StatSaleFunnelDocsRequestDocFiltersDeal,
  StatSaleFunnelDocsRequestDocFiltersShipment,
  StatSaleFunnelDocsRequestDocsTypeEnum,
  StatSaleFunnelDocsResponse,
  StatSaleFunnelGraphCosts,
  StatSaleFunnelGraphsResponse,
} from '../api/marketx';
import { quickDateRanges, RangeVariant } from '../components/SelectDateRange/MenuButtonNew';
import { AppDeal } from '../slices/AppDeal';
import { mapDeal } from '../slices/AppDeal/lib';
import { TotalStoreEntity, totalStoreGenerate } from '../components/Documents/DocumentTotalCount';
import { WarehouseListStoreNew } from './WarehouseListStore';
import { formatDateSwagger } from '@mx-ui/helpers';

export const indicatorsItemTranslate = {
  deal: 'Заявка',
  dealSubmitted: 'Подтверждено',
  bill: 'Счет',
  billReserved: 'Резерв',
  shipment: 'Отгрузка',
};

export interface IndicatorsSelectedCrumb {
  code?: Code; //* должно быть обязательно
  title?: string;
}
const IndicatorsRoleEmployeeTypes = <const>['company', 'division', 'office', 'department', 'employee'];
export type IndicatorsRoleEmployee = (typeof IndicatorsRoleEmployeeTypes)[number];

export const IndicatorsTabNamesTypes = <const>['weight', 'cost'];
export type IndicatorsTabNames = (typeof IndicatorsTabNamesTypes)[number];
export const IndicatorsTabMap: Record<IndicatorsTabNames, IndicatorsTabNames> = {
  weight: 'weight',
  cost: 'cost',
};

export const INDICATORS_ROLE_EMPLOYEE_CODES = {
  employee: 'employee' as IndicatorsRoleEmployee,
  department: 'department' as IndicatorsRoleEmployee,
  division: 'division' as IndicatorsRoleEmployee,
  company: 'company' as IndicatorsRoleEmployee,
  office: 'office' as IndicatorsRoleEmployee,
};

// export const employeeSetByCrumbs = {
//   company: '~myCompany',
//   division: '~myDivision',
//   office: '~myOffice',
//   department: '~myDirectSubdivision',
//   employee: '~emp~2~',
// };

export const INDICATORS_TAB_NAME_CODES = {
  weight: 'weight' as IndicatorsTabNames,
  cost: 'cost' as IndicatorsTabNames,
  count: 'count' as IndicatorsTabNames,
};

const allowTabsByRole: Record<IndicatorsRoleEmployee, IndicatorsTabNames[]> = {
  employee: [INDICATORS_TAB_NAME_CODES.weight, INDICATORS_TAB_NAME_CODES.cost, INDICATORS_TAB_NAME_CODES.count],
  department: [INDICATORS_TAB_NAME_CODES.weight, INDICATORS_TAB_NAME_CODES.cost, INDICATORS_TAB_NAME_CODES.count],
  office: [INDICATORS_TAB_NAME_CODES.weight, INDICATORS_TAB_NAME_CODES.cost, INDICATORS_TAB_NAME_CODES.count],
  division: [INDICATORS_TAB_NAME_CODES.weight, INDICATORS_TAB_NAME_CODES.cost, INDICATORS_TAB_NAME_CODES.count],
  company: [INDICATORS_TAB_NAME_CODES.weight, INDICATORS_TAB_NAME_CODES.cost, INDICATORS_TAB_NAME_CODES.count],
};

export function transformIndicatorsQueryToAllowedParams<T>(query: IndicatorsAllowedParams): T {
  const obj = {} as any;
  const date = new Date();
  if (query.mode && IndicatorsRoleEmployeeTypes.indexOf(query.mode as any) !== -1) {
    obj.mode = query.mode as RoleEmployee;
  }
  if (query.tab && IndicatorsTabNamesTypes.indexOf(query.tab as any) !== -1) {
    obj.tab = query.tab as TabNames;
  } else {
    obj.tab = INDICATORS_TAB_NAME_CODES.weight;
  }
  if (query.selectedCrumbCode) {
    obj.selectedCrumbCode = query.selectedCrumbCode as string;
  }
  if (query.quickRange) {
    obj.quickRange = query.quickRange as string;
  } else {
    obj.quickRange = 'current_month';
  }
  if (query.dateFrom) {
    obj.dateFrom = query.dateFrom as string;
  } else {
    obj.dateFrom = new Date(date.getFullYear(), date.getMonth(), 1);
  }
  if (query.dateTo) {
    obj.dateTo = query.dateTo as string;
  } else {
    obj.dateTo = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }
  if (query.docsType && query.docsType in DocsTypeMap) {
    obj.docsType = query.docsType;
  }

  return obj;
}

const mapPeriodRation = (
  selectedTabData: StatSaleFunnelGraphCosts
): { indicatorValues: IndicatorValuesAndRatio; indicatorTotalRatio: IndicatorValuesAndRatio } => {
  const indicatorTotalRatio = {
    deal: 0,
    dealSubmitted: 0,
    bill: 0,
    billReserved: 0,
    shipment: 0,
  };
  const indicatorValues = {
    deal: selectedTabData?.deal || 0,
    dealSubmitted: selectedTabData?.dealSubmitted || 0,
    bill: selectedTabData?.bill || 0,
    billReserved: selectedTabData?.billReserved || 0,
    shipment: selectedTabData?.shipment || 0,
  };

  if (selectedTabData) {
    const sortIndicatorsStatsValues = Object.entries(indicatorValues).sort((a, b) => b[1] - a[1]);
    sortIndicatorsStatsValues.forEach((item, index, arr) => {
      if (index === 0) {
        indicatorTotalRatio[item[0]] = item[1] === 0 ? 0 : 100;
      } else {
        indicatorTotalRatio[item[0]] = arr[0][1] === 0 ? 0 : Math.round((item[1] / arr[0][1]) * 100);
      }
    });
  }
  return { indicatorValues, indicatorTotalRatio };
};

const mapPeriodRationConversion = (
  selectedTabData: StatSaleFunnelGraphCosts
): { indicatorValuesConversion: IndicatorValuesAndRatio; indicatorTotalRatioConversion: IndicatorValuesAndRatioConversion } => {
  const indicatorTotalRatioConversion: IndicatorValuesAndRatioConversion = {
    deal: {
      total: 0,
      delta: 100 - Math.floor(((selectedTabData.dealSubmitted || 0) / selectedTabData.deal) * 100),
      colored: Math.floor(((selectedTabData.dealSubmitted || 0) / selectedTabData.deal) * 100),
    },
    dealSubmitted: {
      total: 0,
      delta: 100 - Math.floor(((selectedTabData.bill || 0) / selectedTabData.dealSubmitted) * 100),
      colored: Math.floor(((selectedTabData.bill || 0) / selectedTabData.dealSubmitted) * 100),
    },
    bill: {
      total: 0,
      delta: 100 - Math.floor(((selectedTabData.billReserved || 0) / selectedTabData.bill) * 100),
      colored: Math.floor(((selectedTabData.billReserved || 0) / selectedTabData.bill) * 100),
    },
    billReserved: {
      total: 0,
      delta: 100 - Math.floor(((selectedTabData.shipment || 0) / selectedTabData.billReserved) * 100),
      colored: Math.floor(((selectedTabData.shipment || 0) / selectedTabData.billReserved) * 100),
    },
    shipment: {
      total: 0,
      delta: 0,
      colored: 100,
    },
  };
  const indicatorValuesConversion = {
    deal: selectedTabData?.deal || 0,
    dealSubmitted: selectedTabData?.dealSubmitted || 0,
    bill: selectedTabData?.bill || 0,
    billReserved: selectedTabData?.billReserved || 0,
    shipment: selectedTabData?.shipment || 0,
  };
  if (selectedTabData) {
    const sortIndicatorsStatsValues = Object.entries(indicatorValuesConversion).sort((a, b) => b[1] - a[1]);
    sortIndicatorsStatsValues.forEach((item, index, arr) => {
      if (index === 0) {
        indicatorTotalRatioConversion[item[0]].total = item[1] === 0 ? 0 : 100;
      } else {
        indicatorTotalRatioConversion[item[0]].total = arr[0][1] === 0 ? 0 : Math.round((item[1] / arr[0][1]) * 100);
      }
    });
  }
  return { indicatorValuesConversion, indicatorTotalRatioConversion };
};

export interface IndicatorsAllowedParams {
  tab?: IndicatorsTabNames;
  selectedCrumbCode?: string;
  mode?: IndicatorsRoleEmployee;
  dateFrom?: string;
  dateTo?: string;
  quickRange?: string;
  docsType?: StatSaleFunnelDocsRequestDocsTypeEnum;
  funnelType?: IndicatorVariants;
  count?: number;
  page?: number;
  isDelta?: boolean;
  queryCustomer?: string;
  queryWarehouses?: string;
}

export const namesCrumbsIndicatorsNoValue: Record<IndicatorsRoleEmployee, string> = {
  employee: 'Все менеджеры',
  department: 'Все отделы',
  office: 'Все филиалы',
  division: 'Все дивизионы',

  company: '',
};

export type IndicatorVariants = 'periodic' | 'conversion';
export const IndicatorVariantsMap: Record<IndicatorVariants, IndicatorVariants> = {
  periodic: 'periodic',
  conversion: 'conversion',
};
export const DocsTypeMap: Record<StatSaleFunnelDocsRequestDocsTypeEnum, StatSaleFunnelDocsRequestDocsTypeEnum> = {
  deal: 'deal',
  bill: 'bill',
  billReserved: 'billReserved',
  shipment: 'shipment',
  dealApprovalAsk: 'dealApprovalAsk',
  dealApprovalAskDelta: 'dealApprovalAskDelta',
  billDelta: 'billDelta',
  billReservedDelta: 'billReservedDelta',
  shipmentDelta: 'shipmentDelta',
};

interface IConversion {
  total: number;
  delta: number;
  colored: number;
}
interface IndicatorValuesAndRatioConversion {
  deal: IConversion;
  dealSubmitted: IConversion;
  bill: IConversion;
  billReserved: IConversion;
  shipment: IConversion;
}
interface IndicatorValuesAndRatio {
  deal: number;
  dealSubmitted: number;
  bill: number;
  billReserved: number;
  shipment: number;
}
export class IndicatorsPanelStore {
  apiStore: ApiStore;
  totalStoreEntity: TotalStoreEntity = null;
  snackbarStore: SnackbarStore;
  authStore: AuthStore;
  routerStore: RouterStore;

  mode: IndicatorsRoleEmployee;
  role: IndicatorsRoleEmployee;
  allowTabs: IndicatorsTabNames[] = [];
  indicatorVariant: IndicatorVariants = IndicatorVariantsMap.periodic;
  // selectedTab: IndicatorsTabNames = INDICATORS_TAB_NAME_CODES.weight;
  isLoadedTabs = {
    weight: false,
    cost: false,
    count: false,
  };
  crumbsValues: Record<IndicatorsRoleEmployee, IndicatorsSelectedCrumb[]> = {
    company: [],
    division: [],
    office: [],
    department: [],
    employee: [],
  };
  selectedCrumbs: Record<IndicatorsRoleEmployee, IndicatorsSelectedCrumb> = {
    company: undefined,
    division: undefined,
    office: undefined,
    department: undefined,
    employee: undefined,
  };

  enabledCrumbs: IndicatorsRoleEmployee[] = [];
  routerControlEnabled = false;
  request: IndicatorsAllowedParams = {
    count: 20,
    page: 1,
    isDelta: false,
  };
  docFilters: StatSaleFunnelDocsRequestDocFilters = {};
  dealStatuses: DealState[] = [];
  quickRange: RangeVariant = quickDateRanges.find(t => t.value === 'current_month');
  isLoading = false;
  isLoaded = false;
  isDocsLoading = false;
  isDocsLoadingMore = false;
  hasMore = false;
  isFilterCustomerLoading = false;
  documentsCustomersFilterList: CustomerAsFilter[] = [];
  approvalStatuses: DocumentApprovalState[] = [];
  shipmentTypes: SaleShipmentType[] = [];
  warehousesFilterList: SaleShipmentType[] = [];
  indicatorsStatsRatio: IndicatorValuesAndRatio = {
    deal: 0,
    dealSubmitted: 0,
    bill: 0,
    billReserved: 0,
    shipment: 0,
  };
  indicatorsStatsValues: IndicatorValuesAndRatio = {
    deal: 0,
    dealSubmitted: 0,
    bill: 0,
    billReserved: 0,
    shipment: 0,
  };
  indicatorsStatsRatioConversion: IndicatorValuesAndRatioConversion = {
    deal: {
      total: 0,
      delta: 0,
      colored: 0,
    },
    dealSubmitted: {
      total: 0,
      delta: 0,
      colored: 0,
    },
    bill: {
      total: 0,
      delta: 0,
      colored: 0,
    },
    billReserved: {
      total: 0,
      delta: 0,
      colored: 0,
    },
    shipment: {
      total: 0,
      delta: 0,
      colored: 0,
    },
  };
  indicatorsStatsValuesConversion: IndicatorValuesAndRatio = {
    deal: 0,
    dealSubmitted: 0,
    bill: 0,
    billReserved: 0,
    shipment: 0,
  };

  docsType: StatSaleFunnelDocsRequestDocsTypeEnum;
  dealsIndex: Record<string, AppDeal> = {};
  docs: {
    bills?: Array<Bill>;
    deals?: Array<AppDeal>;
    saleShipments?: Array<SaleShipment>;
    totalCount?: number;
  } = null;

  indicatorsStatsChecked = {
    deal: true,
    dealSubmitted: false,
    bill: false,
    billReserved: false,
    shipment: true,
  };
  indicatorsStatsCheckedConversion = {
    deal: true,
    dealSubmitted: false,
    bill: false,
    billReserved: false,
    shipment: true,
  };
  _dateFormatIndicators = {
    from: '',
    to: '',
  };

  graph: StatSaleFunnelGraphsResponse = {};
  billsStatuses: BillState[] = [];
  billShipmentsState: BillShipmentsState[] = [];
  billPaymentStates: PaymentState[] = [];
  billsSources: BillSource[] = [];

  ignoreBeforeDate?: Date;
  isWarehousesLoading = false;

  set indicatorsDate({ from, to }) {
    this._dateFormatIndicators.from = formatDateSwagger(from);
    this._dateFormatIndicators.to = formatDateSwagger(to);
  }

  get indicatorsDateSwagger(): { from: string; to: string } {
    return {
      from: formatDateSwagger(this._dateFormatIndicators.from),
      to: formatDateSwagger(this._dateFormatIndicators.to),
    };
  }

  controller: AbortController = new AbortController();

  warehouseListStore: WarehouseListStoreNew;

  get docsTypeForDocs(): 'deal' | 'bill' | 'shipment' | undefined {
    if (
      this.request.docsType === 'deal' ||
      this.request.docsType === 'dealApprovalAsk' ||
      this.request.docsType === 'dealApprovalAskDelta' ||
      this.request.docsType === 'billDelta'
    ) {
      return 'deal';
    } else if (
      this.request.docsType === 'bill' ||
      this.request.docsType === 'billReservedDelta' ||
      this.request.docsType === 'billReserved' ||
      this.request.docsType === 'shipmentDelta'
    ) {
      return 'bill';
    } else if (this.request.docsType === 'shipment') {
      return 'shipment';
    }
    return;
  }
  constructor(rootStore: RootStore) {
    this.snackbarStore = rootStore.getSnackbar();
    this.apiStore = rootStore.getApiStore();
    this.authStore = rootStore.getAuth();
    this.routerStore = rootStore.getRouter();
    this.warehouseListStore = new WarehouseListStoreNew(rootStore);
    this.role = this.getTypeByRole();
    this.request = observable({});
    this.setMode(this.role);
    makeAutoObservable(this, { apiStore: false, snackbarStore: false, getTypeByRole: false }, { autoBind: true });
  }

  loadWarehouseList(branchOfficeCode?: string): void {
    if (branchOfficeCode) {
      this.warehouseListStore.loadList(branchOfficeCode);
    }
  }

  changeWarehouse(branchOfficeCode?: string): void {
    this.setShipmentDocFilters({ warehousesCodes: [branchOfficeCode] });
    this.loadStatIndicatorsDocs();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadWarehousesForFilter(queryCustomer?: string): void {
    //   if (queryCustomer) {
    //     this.request.queryWarehouses = queryCustomer;
    //   } else {
    //     this.request.queryWarehouses = undefined;
    //   }
    //   this.loadWarehouses();
  }
  // loadWarehouses(r?: ShipmentsListRequest): void {
  //   this.ignoreBeforeDate = new Date();
  //   runInAction(() => {
  //     this.isWarehousesLoading = true;
  //   });
  //   const req = toJS(r || this.docFilters.shipment);
  //   this.apiStore
  //     .apiWarehousesForFilter()
  //     .warehousesForFilter(
  //       'shipment',
  //       req.warehousesCodes?.length ? req.warehousesCodes : undefined,
  //       undefined,
  //       undefined,
  //       undefined,
  //       undefined,
  //       undefined,
  //       undefined,
  //       req.paymentStateCodes?.length ? req.paymentStateCodes : undefined,
  //       req.shipmentTypeCode || undefined,
  //       this.request.dateFrom ? formatDateSwagger(this.request.dateFrom) : undefined,
  //       this.request.dateTo ? formatDateSwagger(this.request.dateTo) : undefined,
  //       this.mode !== 'employee' ? employeeSetByCrumbs[this.mode] : employeeSetByCrumbs[this.mode] + this.selectedCrumbs.employee.code,
  //       req.query || undefined,
  //       1,
  //       100,
  //       this.request.queryWarehouses || undefined
  //     )
  //     .then(res => {
  //       this.setWarehousesResult(res.data);
  //     })
  //     .catch(err => {
  //       console.warn('loadWarehousesRequest', err);
  //     });
  // }

  // setWarehousesResult(data: WarehousesForFilterResponse): void {
  //   const prevCheckedCustomers = [];
  //   this.warehousesFilterList?.forEach(prevI => {
  //     if (this.docFilters.shipment?.warehousesCodes?.includes(prevI.code) && !data.warehouses.find(i => i.code === prevI.code)) {
  //       prevCheckedCustomers.push(prevI);
  //     }
  //   });
  //   if (data.warehouses?.length) {
  //     this.warehousesFilterList = [...prevCheckedCustomers, ...data.warehouses];
  //   }
  //   this.warehousesFilterList = data.warehouses;
  //   runInAction(() => {
  //     this.isWarehousesLoading = false;
  //   });
  // }

  loadShipmentTypeCodes(): void {
    this.apiStore
      .apiShipments()
      .shipmentsTypes()
      .then(res => {
        this.setShipmentTypeCodesResult(res.data);
      })
      .catch(err => {
        console.warn('loadShipmentTypeCodesRequest', err);
      });
  }

  setShipmentTypeCodesResult(data: ShipmentsTypesResponse): void {
    this.shipmentTypes = data.shipmentTypes;
  }

  loadBillsSources(): void {
    this.apiStore
      .apiClientBill()
      .billsSources()
      .then(res => {
        this.setBillsSourcesResult(res.data);
      })
      .catch(e => {
        console.warn('loadBillsSourcesК', e);
      });
  }
  setBillsSourcesResult(data: BillsSourcesResponse): void {
    this.billsSources = data.sources || [];
  }

  loadBillPaymentStates(): void {
    this.apiStore
      .apiClientPayment()
      .paymentStates()
      .then(res => {
        this.setBillPaymentStates(res.data);
      })
      .catch(e => {
        console.warn('loadDealStatuses', e);
      });
  }
  setBillPaymentStates(data: PaymentStatesResponse): void {
    this.billPaymentStates = data.paymentStates || [];
  }

  loadBillShipmentStates(): void {
    this.apiStore
      .apiClientBill()
      .billsShipmentStates()
      .then(res => {
        this.setBillShipmentStates(res.data);
      })
      .catch(e => {
        console.warn('loadDealStatuses', e);
      });
  }
  setBillShipmentStates(data: BillsShipmentStatesResponse): void {
    this.billShipmentsState = data.billShipmentsStates || [];
  }

  loadBillsStatuses(): void {
    this.apiStore
      .apiClientBill()
      .billsStates()
      .then(res => {
        this.setBillsStatusesResult(res.data);
      })
      .catch(e => {
        console.warn('loadBillStatuses', e);
      });
  }

  setBillsStatusesResult(data: BillsStatesResponse): void {
    this.billsStatuses = data.states || [];
  }

  loadApprovalStatuses(): void {
    this.apiStore
      .apiDocuments()
      .documentsApprovalStates()
      .then(res => {
        this.setApprovalStatusesResult(res.data);
      })
      .catch(e => {
        console.warn('loadApprovalStatuses req', e);
      });
  }
  setApprovalStatusesResult(data: DocumentsApprovalStatesResponse): void {
    this.approvalStatuses = data.states;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadCustomersForFilter(queryCustomer?: string): void {
    //   if (queryCustomer) {
    //     this.request.queryCustomer = queryCustomer;
    //   } else {
    //     this.request.queryCustomer = undefined;
    //   }
    //   this.loadFilterCustomerCodes();
  }

  // loadFilterCustomerCodes(): void {
  //   if (!this.docsTypeForDocs) {
  //     return;
  //   }
  //   runInAction(() => {
  //     this.isFilterCustomerLoading = true;
  //   });
  //   let request = {};
  //   if (this.docsTypeForDocs === 'deal') {
  //     const req = Object.assign({}, this.docFilters.deal);
  //     request = {
  //       documentType: 'deal',
  //       approvalStateCodes: req.approvalStateCodes?.length ? req.approvalStateCodes : undefined,
  //       requiredCustomerCodes: req.customerCodes?.length ? req.customerCodes : undefined,
  //       dateFrom: this.request.dateFrom ? formatDateSwagger(this.request.dateFrom) : undefined,
  //       dateTo: this.request.dateTo ? formatDateSwagger(this.request.dateTo) : undefined,
  //       queryDocuments: req.query || undefined,
  //       dealStateCodes: req.stateCodes?.length ? req.stateCodes : undefined,
  //       count: 100,
  //       queryCustomers: this.request.queryCustomer || undefined,
  //     };
  //   }
  //   if (this.docsTypeForDocs === 'bill') {
  //     const req = Object.assign({}, this.docFilters.bill);
  //     request = {
  //       documentType: 'bill',
  //       requiredCustomerCodes: req.customerCodes?.length ? req.customerCodes : undefined,
  //       dateFrom: this.request.dateFrom ? formatDateSwagger(this.request.dateFrom) : undefined,
  //       dateTo: this.request.dateTo ? formatDateSwagger(this.request.dateTo) : undefined,
  //       hasShipments: req.hasShipments || undefined,
  //       paymentStateCodes: req.paymentStateCodes?.length ? req.paymentStateCodes : undefined,
  //       billShipmentStateCodes: req.shipmentStateCodes?.length ? req.shipmentStateCodes : undefined,
  //       billSourceCodes: req.sourceCodes?.length ? req.sourceCodes : undefined,
  //       billStateCodes: req.stateCodes?.length ? req.stateCodes : undefined,
  //       queryDocuments: req.query || undefined,
  //       count: 100,
  //       queryCustomers: this.request.queryCustomer || undefined,
  //     };
  //   }
  //   if (this.docsTypeForDocs === 'shipment') {
  //     const req = Object.assign({}, this.docFilters.shipment);
  //     request = {
  //       documentType: 'shipment',
  //       hasEmployeeDeputy: !!req.hasEmployeeDeputy,
  //       requiredCustomerCodes: req.customerCodes?.length ? req.customerCodes : undefined,
  //       dateFrom: this.request.dateFrom ? formatDateSwagger(this.request.dateFrom) : undefined,
  //       dateTo: this.request.dateTo ? formatDateSwagger(this.request.dateTo) : undefined,
  //       paymentStateCodes: req.paymentStateCodes?.length ? req.paymentStateCodes : undefined,
  //       queryDocuments: req.query || undefined,
  //       shipmentTypeCode: req.shipmentTypeCode || undefined,
  //       warehousesCodes: req.warehousesCodes?.length ? req.warehousesCodes : undefined,
  //       count: 100,
  //       queryCustomers: this.request.queryCustomer || undefined,
  //     };
  //   }
  //   this.apiStore
  //     .apiClientCustomer()
  //     .clientsForFilter(request, {
  //       signal: this.controller.signal,
  //     })
  //     .then(res => {
  //       this.setFilterCustomerResult(res.data);
  //     })
  //     .catch(e => {
  //       console.warn('loadFilterCustomerCodes req', e);
  //       runInAction(() => {
  //         this.isFilterCustomerLoading = false;
  //       });
  //     });
  // }
  // setFilterCustomerResult(statuses: ClientsForFilterResponse): void {
  //   if (statuses.customers?.length) {
  //     const prevCheckedCustomers = [];
  //     this.documentsCustomersFilterList.forEach(prevI => {
  //       if (this.docFilters.deal?.customerCodes?.includes(prevI.code) && !statuses.customers.find(i => i.code === prevI.code)) {
  //         prevCheckedCustomers.push(prevI);
  //       }
  //     });
  //
  //     if (this.docFilters.deal?.customerCodes?.length) {
  //       this.documentsCustomersFilterList = [...prevCheckedCustomers, ...statuses.customers];
  //     } else {
  //       this.documentsCustomersFilterList = [...statuses.customers];
  //     }
  //   }
  //   runInAction(() => {
  //     this.isFilterCustomerLoading = false;
  //   });
  // }
  loadDealStatuses(): void {
    this.apiStore
      .apiClientDeal()
      .dealsStates()
      .then(res => {
        this.setDealStatusesResult(res.data);
      })
      .catch(e => {
        console.warn('loadDealStatuses req', e);
      });
  }
  setDealStatusesResult(statuses: DealsStatesResponse): void {
    this.dealStatuses = statuses.states;
  }

  get indexesOfIndicatorsItemCheckedGet(): { first: number | null; last: number | null; isLineShow: boolean } {
    const currentObj = this.indicatorsStatsChecked;
    const sort = Object.keys(currentObj).reduce((acc, item, index) => {
      if (currentObj[item]) {
        acc.push(index);
      }
      return acc;
    }, []);
    return {
      first: typeof sort[0] === 'number' ? sort[0] : null,
      last: typeof sort[1] === 'number' ? sort[1] : null,
      isLineShow: sort.length > 1,
    };
  }

  get indexesOfIndicatorsItemCheckedGetConversion(): { first: number | null; last: number | null; isLineShow: boolean } {
    const currentObj = this.indicatorsStatsCheckedConversion;
    const sort = Object.keys(currentObj).reduce((acc, item, index) => {
      if (currentObj[item]) {
        acc.push(index);
      }
      return acc;
    }, []);
    return {
      first: typeof sort[0] === 'number' ? sort[0] : null,
      last: typeof sort[1] === 'number' ? sort[1] : null,
      isLineShow: sort.length > 1,
    };
  }

  setIndicatorsTab(unit: IndicatorsTabNames): void {
    // this.selectedTab = unit;
    this.setRequestFromObj({ ...this.request, tab: unit });
    this.actualizeRouter(this.request);
    this.loadStatByTab(unit);
  }

  setIndicatorsItemChecked(indicatorsItemName: string): void {
    const currentObj = this.indicatorsStatsChecked;
    const checkedCount = Object.keys(currentObj).reduce((acc, item) => acc + currentObj[item], 0);
    if (checkedCount === 2 && !currentObj[indicatorsItemName]) {
      Object.keys(currentObj).forEach(item => {
        if (item === indicatorsItemName) {
          currentObj[item] = !currentObj[item];
        } else {
          currentObj[item] = false;
        }
      });
    } else {
      currentObj[indicatorsItemName] = !currentObj[indicatorsItemName];
    }
  }

  setIndicatorsItemCheckedConversion(indicatorsItemName: string): void {
    const currentObj = this.indicatorsStatsCheckedConversion;
    const checkedCount = Object.keys(currentObj).reduce((acc, item) => acc + currentObj[item], 0);
    if (checkedCount === 2 && !currentObj[indicatorsItemName]) {
      Object.keys(currentObj).forEach(item => {
        if (item === indicatorsItemName) {
          currentObj[item] = !currentObj[item];
        } else {
          currentObj[item] = false;
        }
      });
    } else {
      currentObj[indicatorsItemName] = !currentObj[indicatorsItemName];
    }
  }

  setIndicatorVariant(indicatorVariant: IndicatorVariants): void {
    this.indicatorVariant = indicatorVariant;
  }

  getTypeByRole(): IndicatorsRoleEmployee {
    const isDivisionChief = this.authStore.inGroup(AppUserGroupCodes.DIVISION_CHIEF);
    const isDivisionMarketer = this.authStore.inGroup(AppUserGroupCodes.DIVISION_MARKETER);
    const isOfficeChief = this.authStore.inGroup(AppUserGroupCodes.OFFICE_CHIEF);
    const isOfficeMarketer = this.authStore.inGroup(AppUserGroupCodes.OFFICE_MARKETER);
    const isSellerChief = this.authStore.inGroup(AppUserGroupCodes.SELLER_CHIEF);
    const isCompanyChief = this.authStore.inGroup(AppUserGroupCodes.COMPANY_CHIEF);
    const isCompanyMarketer = this.authStore.inGroup(AppUserGroupCodes.COMPANY_MARKETER);
    const isSupport = this.authStore.inGroup(AppUserGroupCodes.SUPPORT);

    if (isCompanyChief || isCompanyMarketer) {
      return INDICATORS_ROLE_EMPLOYEE_CODES.company;
    }
    if (isDivisionChief || isDivisionMarketer) {
      return INDICATORS_ROLE_EMPLOYEE_CODES.division;
    }
    if (isOfficeChief || isSupport || isOfficeMarketer) {
      return INDICATORS_ROLE_EMPLOYEE_CODES.office;
    }
    if (isSellerChief) {
      return INDICATORS_ROLE_EMPLOYEE_CODES.department;
    }
    INDICATORS_ROLE_EMPLOYEE_CODES;
    return INDICATORS_ROLE_EMPLOYEE_CODES.employee;
  }

  setMode(mode: IndicatorsRoleEmployee): boolean {
    if (mode === this.mode) {
      return false;
    }
    const roleIndex = IndicatorsRoleEmployeeTypes.indexOf(this.role);
    const modeParamIndex = IndicatorsRoleEmployeeTypes.indexOf(this.request.mode);
    if (roleIndex > modeParamIndex && this.request.mode) {
      console.warn('Нельзя установить mode выше роли. Значение mode установится по умолчанию');
      this.snackbarStore.showInfo('Вам недоступен просмотр этого уровня. Значения установятся по умолчанию');
      this.mode = this.role;
      return false;
    }
    this.setSelectedTab(this.request.tab);

    this.mode = mode;
    Object.keys(this.isLoadedTabs).forEach(tab => {
      this.isLoadedTabs[tab] = false;
    });
    return true;
  }

  /**
   * Смена таба
   */
  setSelectedTab(value: IndicatorsTabNames): void {
    this.setRequestFromObj(observable({ tab: value }));
    this.actualizeRouter(this.request);
  }

  loadStatByTab(tab: IndicatorsTabNames): void {
    const role = this.mode;
    if (this.isLoadedTabs[tab]) {
      return;
    }
    switch (tab) {
      case INDICATORS_TAB_NAME_CODES.weight:
        this.loadStatIndicatorsStat(role);
        break;
      case INDICATORS_TAB_NAME_CODES.cost:
        this.loadStatIndicatorsStat(role);
        break;
      default:
        console.warn('tab received does not exist');
        break;
    }
  }

  resetRequest(): void {
    this.setRequestFromObj({
      ...this.request,
      page: 1,
      funnelType: undefined,
      docsType: undefined,
    });
    set(this.docFilters, {});
    this.resetDocs();
  }

  resetDocs(): void {
    if (this.isDocsLoading || this.isDocsLoadingMore) {
      this.controller.abort();
    }
    this.docs = null;
    this.dealsIndex = {};
  }

  setRequestFromObj(req: IndicatorsAllowedParams): void {
    set(this.request, req);
  }

  actualizeRouter(req: IndicatorsAllowedParams): void {
    if (!this.routerControlEnabled) {
      return;
    }
    const params = new URLSearchParams();
    if (req.tab) {
      params.set('tab', req.tab);
    }
    if (req.selectedCrumbCode) {
      params.set('selectedCrumbCode', req.selectedCrumbCode);
    }
    if (req.mode) {
      params.set('mode', req.mode);
    }
    if (req.quickRange) {
      params.set('quickRange', req.quickRange);
    }
    if (req.dateFrom) {
      params.set('dateFrom', formatDateSwagger(req.dateFrom));
    }
    if (req.dateTo) {
      params.set('dateTo', formatDateSwagger(req.dateTo));
    }

    let paramsStr = params.toString();
    if (paramsStr) {
      paramsStr = '?' + paramsStr;
    }
    let url = '/app/report/indicators';
    url += paramsStr;
    this.routerStore.replace(url, undefined, { shallow: true });
  }

  setDate(dateFrom: Date, dateTo: Date): void {
    this.indicatorsDate = { from: dateFrom, to: dateTo };
  }

  async initialLoadIndicators(): Promise<void> {
    const role = this.role;
    this.allowTabs = allowTabsByRole[role];
    await this.loadStatIndicatorsReferences({}, true);
    await this.loadStatIndicatorsStat(role);
  }

  async initialLoadStatsByQuery(params: IndicatorsAllowedParams, forHomePage = false): Promise<void> {
    if ((this.role === 'division' || this.role === 'company') && !params.mode && !forHomePage) {
      params.mode = 'office';
      params.selectedCrumbCode = this.authStore.profile.chosenBranchOfficeCode;
    }
    const role = this.role;
    this.changeQuickRange(quickDateRanges.find(t => t.value === params.quickRange));
    await runInAction(async () => {
      this.setRequestFromObj(params);
      if (params.mode) {
        this.setMode(params.mode);
        this.allowTabs = allowTabsByRole[this.mode];
        return await this.loadStatIndicatorsReferences({ [this.mode]: params.selectedCrumbCode }, true);
      } else {
        this.allowTabs = allowTabsByRole[role];
        return await this.loadStatIndicatorsReferences({}, true);
      }
    });
  }
  changeQuickRange(v: RangeVariant): void {
    this.quickRange = v;
    set(this.request, { quickRange: v.value });
    this.actualizeRouter(this.request);
  }
  async loadStatIndicatorsStat(role: IndicatorsRoleEmployee): Promise<void> {
    const tabName = this.request.tab || INDICATORS_TAB_NAME_CODES.weight;

    switch (role) {
      case INDICATORS_ROLE_EMPLOYEE_CODES.division:
        await this.loadStatIndicatorsDivisions(tabName, this.selectedCrumbs[role]?.code);
        break;
      case INDICATORS_ROLE_EMPLOYEE_CODES.office:
        await this.loadStatIndicatorsOffices(tabName, this.selectedCrumbs[role]?.code);
        break;
      case INDICATORS_ROLE_EMPLOYEE_CODES.department:
        await this.loadStatIndicatorsDepartments(tabName, this.selectedCrumbs[role]?.code);
        break;
      case INDICATORS_ROLE_EMPLOYEE_CODES.employee:
        await this.loadStatIndicatorsEmployees(tabName, this.selectedCrumbs[role]?.code);
        break;
      case INDICATORS_ROLE_EMPLOYEE_CODES.company:
        await this.loadStatIndicatorsCompany(tabName, this.selectedCrumbs[role]?.code);
        break;

      default:
        console.warn('role received does not exist');
        break;
    }
  }

  async loadStatIndicatorsCompany(tab: IndicatorsTabNames, companyCode: Code): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const currentDate = this._dateFormatIndicators;
    try {
      const res = await this.apiStore.apiStatApi().statSaleFunnelGraphs({
        dateFrom: currentDate.from,
        dateTo: currentDate.to,
        headCompanyCode: companyCode,
        viewMode: this.request.tab || 'weight',
      });

      this.setResultIndicatorsGraphs(res.data, tab);
    } catch (err) {
      console.warn('loadStatIndicatorsEmployees', err);
    }
  }

  async loadStatIndicatorsEmployees(tab: IndicatorsTabNames, employeeCode: Code): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const currentDate = this._dateFormatIndicators;
    try {
      const res = await this.apiStore.apiStatApi().statSaleFunnelGraphs({
        dateFrom: currentDate.from,
        dateTo: currentDate.to,
        employeeCodes: [employeeCode],
        viewMode: this.request.tab || 'weight',
      });

      this.setResultIndicatorsGraphs(res.data, tab);
    } catch (err) {
      console.warn('loadStatIndicatorsEmployees', err);
    }
  }

  async loadStatIndicatorsDepartments(tab: IndicatorsTabNames, departmentCode: Code): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const currentDate = this._dateFormatIndicators;
    try {
      const res = await this.apiStore.apiStatApi().statSaleFunnelGraphs({
        dateFrom: currentDate.from,
        dateTo: currentDate.to,
        departmentCodes: [departmentCode],
        viewMode: this.request.tab || 'weight',
      });

      this.setResultIndicatorsGraphs(res.data, tab);
    } catch (err) {
      console.warn('loadStatIndicatorsDepartments', err);
    }
  }

  async loadStatIndicatorsOffices(tab: IndicatorsTabNames, officeCode: Code): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const currentDate = this._dateFormatIndicators;
    try {
      const res = await this.apiStore.apiStatApi().statSaleFunnelGraphs({
        dateFrom: currentDate.from,
        dateTo: currentDate.to,
        branchOfficeCodes: [officeCode],
        viewMode: this.request.tab || 'weight',
      });

      this.setResultIndicatorsGraphs(res.data, tab);
    } catch (err) {
      console.warn('loadStatIndicatorsOffices', err);
    }
  }

  async loadStatIndicatorsDivisions(tab: IndicatorsTabNames, divisionCode: Code): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const currentDate = this._dateFormatIndicators;
    try {
      const res = await this.apiStore.apiStatApi().statSaleFunnelGraphs({
        dateFrom: currentDate.from,
        dateTo: currentDate.to,
        divisionCodes: [divisionCode],
        viewMode: this.request.tab || 'weight',
      });

      this.setResultIndicatorsGraphs(res.data, tab);
    } catch (err) {
      console.warn('loadStatIndicatorsDivisions', err);
    }
  }

  loadMore(): void {
    if (this.isDocsLoading) {
      // уже загружается
      return;
    }
    this.isDocsLoadingMore = true;
    this.request.page = (this.request?.page || 1) + 1;
    this.loadStatIndicatorsDocs();
  }

  async loadStatIndicatorsDocs(): Promise<void> {
    if (!this.request.docsType) {
      return;
    }
    this.isDocsLoading = true;
    const currentDate = this._dateFormatIndicators;
    try {
      const res = await this.apiStore.apiStatApi().statSaleFunnelDocs(
        {
          dateFrom: currentDate.from,
          dateTo: currentDate.to,
          docsType: this.request.docsType,
          funnelType: this.request.funnelType,
          branchOfficeCodes: this.mode === 'office' ? [this.selectedCrumbs[this.mode]?.code] : undefined,
          headCompanyCode: this.mode === 'company' ? this.selectedCrumbs[this.mode]?.code : undefined,
          divisionCodes: this.mode === 'division' ? [this.selectedCrumbs[this.mode]?.code] : undefined,
          departmentCodes: this.mode === 'department' ? [this.selectedCrumbs[this.mode]?.code] : undefined,
          employeeCodes: this.mode === 'employee' ? [this.selectedCrumbs[this.mode]?.code] : undefined,
          count: this.request.count || 20,
          page: this.request.page || 1,
          docFilters: {
            deal: this.docFilters?.deal || undefined,
            bill: this.docFilters?.bill || undefined,
            shipment: this.docFilters?.shipment || undefined,
          },
          isBranchOfficesRequired: false,
        },
        {
          signal: this.controller.signal,
        }
      );
      this.setDocsResult(res.data);
    } catch (err) {
      console.warn('loadStatIndicatorsDocsReq', err);
      this.isDocsLoading = false;
    }
  }

  setDocsResult(data: StatSaleFunnelDocsResponse): void {
    if (this.isDocsLoadingMore) {
      this.docs.deals.push(...(data.deals?.map(d => observable(mapDeal(d))) || []));
      this.docs.bills.push(...(data.bills || []));
      this.docs.saleShipments.push(...(data.saleShipments || []));
    } else {
      this.docs = {
        deals: data.deals?.map(d => observable(mapDeal(d))) || [],
        bills: data.bills || [],
        saleShipments: data.saleShipments || [],
        totalCount: data.totalCount,
      };
      this.totalStoreEntity = totalStoreGenerate(data.totalCount || 0, data.totalWeight || 0, data.totalCost || 0);
    }

    this.hasMore =
      data.totalCount > 0 &&
      (data.totalCount !== data.deals?.length || data.totalCount !== data.bills?.length || data.totalCount !== data.saleShipments?.length);
    this.isDocsLoading = false;
    this.isDocsLoadingMore = false;
  }

  setDocsType(docsType: string, funnelType: IndicatorVariants): void {
    let type = docsType;
    switch (docsType) {
      case 'deal':
        type = DocsTypeMap.deal;
        break;
      case 'dealSubmitted':
        type = DocsTypeMap.dealApprovalAsk;
        break;
      case 'bill':
        type = DocsTypeMap.bill;
        break;
      case 'billReserved':
        type = DocsTypeMap.billReserved;
        break;
      case 'shipment':
        type = DocsTypeMap.shipment;
        break;
    }
    this.request.docsType = type as StatSaleFunnelDocsRequestDocsTypeEnum;
    this.request.isDelta = false;
    this.request.funnelType = funnelType;
    this.request.page = 1;
    set(this.docFilters, {
      deal: undefined,
      bill: undefined,
      shipment: undefined,
    });
    this.resetDocs();
  }
  setDocsTypeDelta(docsType: string, isDelta: boolean): void {
    let type = docsType;
    switch (docsType) {
      case DocsTypeMap.dealApprovalAskDelta:
        type = DocsTypeMap.deal;
        break;
      case DocsTypeMap.billDelta:
        type = DocsTypeMap.dealApprovalAsk;
        break;
      case DocsTypeMap.billReservedDelta:
        type = DocsTypeMap.bill;
        break;
      case DocsTypeMap.shipmentDelta:
        type = DocsTypeMap.billReserved;
        break;
      case DocsTypeMap.deal:
        type = DocsTypeMap.dealApprovalAskDelta;
        break;
      case DocsTypeMap.dealApprovalAsk:
        type = DocsTypeMap.billDelta;
        break;
      case DocsTypeMap.bill:
        type = DocsTypeMap.billReservedDelta;
        break;
      case DocsTypeMap.billReserved:
        type = DocsTypeMap.shipmentDelta;
        break;
    }
    this.request.isDelta = isDelta;
    this.request.page = 1;
    this.request.docsType = type as StatSaleFunnelDocsRequestDocsTypeEnum;
    this.resetDocs();
  }

  setDealsDocFilters(filter: StatSaleFunnelDocsRequestDocFiltersDeal): void {
    this.request.page = 1;
    set(this.docFilters, {
      deal: { ...this.docFilters.deal, ...filter },
    });
  }

  setBillDocFilters(filter: StatSaleFunnelDocsRequestDocFiltersBill): void {
    this.request.page = 1;
    set(this.docFilters, {
      bill: { ...this.docFilters.bill, ...filter },
    });
  }

  setShipmentDocFilters(filter: StatSaleFunnelDocsRequestDocFiltersShipment): void {
    this.request.page = 1;
    set(this.docFilters, {
      shipment: { ...this.docFilters.shipment, ...filter },
    });
  }

  setResultIndicatorsGraphs(data: StatSaleFunnelGraphsResponse, tab: IndicatorsTabNames): void {
    this.graph = data;
    const selectedTabDataPeriodic: StatSaleFunnelGraphCosts = data?.['periodic']?.[tab + 's'];
    const selectedTabDataConversion: StatSaleFunnelGraphCosts = data?.['conversion']?.[tab + 's'];

    const { indicatorValues, indicatorTotalRatio } = mapPeriodRation(selectedTabDataPeriodic);
    const { indicatorValuesConversion, indicatorTotalRatioConversion } = mapPeriodRationConversion(selectedTabDataConversion);

    this.indicatorsStatsRatio = indicatorTotalRatio;
    this.indicatorsStatsRatioConversion = indicatorTotalRatioConversion;
    this.indicatorsStatsValues = indicatorValues;
    this.indicatorsStatsValuesConversion = indicatorValuesConversion;

    Object.keys(this.isLoadedTabs).forEach(item => {
      this.isLoadedTabs[item] = tab === item;
    });
    this.isLoading = false;
    this.isLoaded = false;
  }

  setRouterControl(enabled: boolean): void {
    this.routerControlEnabled = enabled;
  }

  /**
   * Выбираем значение из списка крошки.
   */
  async setSelectedCrumbAndSetMode(crumbName: IndicatorsRoleEmployee, code: Code): Promise<void> {
    const prevMode = this.mode;
    const prevSelectedCrumbsCode = this.selectedCrumbs[crumbName]?.code;
    const selectedCrumb = this.crumbsValues[crumbName].find(crumb => crumb.code === code);

    if (!this.setMode(crumbName) && prevSelectedCrumbsCode === selectedCrumb.code) {
      return;
    }
    this.setRequestFromObj(observable({ mode: crumbName, selectedCrumbCode: selectedCrumb.code }));
    this.actualizeRouter(this.request);
    Object.keys(this.isLoadedTabs).forEach(tab => {
      this.isLoadedTabs[tab] = false;
    });
    // * если роль последняя то обновляем References(т.к. следующей крошки после этой роли нет, значит можно не обновлять данные)
    if (crumbName !== IndicatorsRoleEmployeeTypes[IndicatorsRoleEmployeeTypes.length - 1]) {
      await this.loadStatIndicatorsReferences({ [crumbName]: selectedCrumb.code });
    }
    this.unSelectCrumbs(prevMode, crumbName);
    runInAction(() => {
      this.allowTabs = allowTabsByRole[crumbName];
      this.selectedCrumbs[crumbName] = selectedCrumb;
    });

    this.loadStatIndicatorsStat(this.mode);
  }

  /**
   * Клик по крошке без меню
   */
  async setModeAndUpdateCrumbs(crumbName: IndicatorsRoleEmployee): Promise<void> {
    const prevMode = this.mode;
    const selectedCrumb = this.selectedCrumbs[crumbName];
    if (!this.setMode(crumbName)) {
      return;
    }
    if (crumbName !== IndicatorsRoleEmployeeTypes[IndicatorsRoleEmployeeTypes.length - 1] && crumbName !== this.role) {
      await this.loadStatIndicatorsReferences({ [crumbName]: selectedCrumb.code });
    }
    this.unSelectCrumbs(prevMode, crumbName);
    runInAction(() => {
      this.allowTabs = allowTabsByRole[crumbName];
    });
    this.setRequestFromObj(observable({ mode: crumbName, selectedCrumbCode: selectedCrumb.code }));
    this.actualizeRouter(this.request);
    this.loadStatIndicatorsStat(this.mode);
  }

  unSelectCrumbs(prevMode: IndicatorsRoleEmployee, newMode: IndicatorsRoleEmployee): void {
    const rolePrevIndex = IndicatorsRoleEmployeeTypes.indexOf(prevMode);
    const roleIndex = IndicatorsRoleEmployeeTypes.indexOf(newMode);

    if (roleIndex < rolePrevIndex) {
      const unSelectedCrumb = IndicatorsRoleEmployeeTypes.slice(roleIndex + 1, IndicatorsRoleEmployeeTypes.length);
      unSelectedCrumb.forEach(crumb => {
        this.selectedCrumbs[crumb] = undefined;
      });
      this.enabledCrumbs = this.enabledCrumbs.filter(crumb => !unSelectedCrumb.includes(crumb));
    }
    for (let nextRoleIndex = roleIndex + 1; nextRoleIndex < IndicatorsRoleEmployeeTypes.length; nextRoleIndex++) {
      const nextRole = IndicatorsRoleEmployeeTypes[roleIndex + 1];
      if (nextRole && this.crumbsValues[nextRole].length >= 1) {
        if (this.crumbsValues[nextRole].length === 1) {
          // Выбираем в крошке единственный вариант
          this.selectedCrumbs[nextRole] = this.crumbsValues[nextRole][0];
        } else if (this.selectedCrumbs[nextRole]) {
          // Сбрасываем выбранный вариант, если его нет в доступном списке
          this.selectedCrumbs[nextRole] = this.crumbsValues[nextRole].find(crumb => crumb === this.selectedCrumbs[nextRole]);
        }
        this.enabledCrumbs.push(nextRole);
      } else {
        this.enabledCrumbs = this.enabledCrumbs.filter(crumb => crumb !== nextRole);
      }
    }
  }
  async loadStatIndicatorsReferences(
    {
      employee: employeeCode,
      department: departmentCode,
      office: branchOfficeCode,
      division: divisionCode,
    }: {
      employee?: Code;
      department?: Code;
      office?: Code;
      division?: Code;
    },
    init = false
  ): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    return await this.apiStore
      .apiStatApi()
      .statMarginalityReferences(employeeCode, departmentCode, branchOfficeCode, divisionCode, this._dateFormatIndicators.from)
      .then(res => {
        this.setResultMarginalityReferences(res, init);
      })
      .catch(err => {
        console.warn('loadStatIndicatorsReferences', err);
      });
  }

  setResultMarginalityReferences({ data }: AxiosResponse<StatReferencesResponse>, init = false): void {
    this.setCrumbsValues(data);
    if (init) {
      const roleIndex = IndicatorsRoleEmployeeTypes.indexOf(this.role);
      const currentMode = this.mode; //* текущий мод из урла. Если нет то тут будет мод по роли и дальше все пойдет по маслу.
      const modeParamIndex = IndicatorsRoleEmployeeTypes.indexOf(currentMode);
      if (roleIndex <= modeParamIndex) {
        // * подгружаем данные в крошки, которые идут ДО крошки по моду (включительно)
        const crumbsBeforeRole = IndicatorsRoleEmployeeTypes.slice(0, modeParamIndex + 1).reverse();

        crumbsBeforeRole.forEach((crumbs, index) => {
          if (this.crumbsValues[crumbs].length > 0) {
            if (crumbs === this.mode) {
              //* ищем, для переданной крошки из урла, значение
              const valueByUrl = this.crumbsValues[crumbs].find(crumb => crumb.code === this.request.selectedCrumbCode);
              if (valueByUrl) {
                this.selectedCrumbs[crumbs] = valueByUrl;
              } else {
                //* может быть такое что не нашлось значение и просто берем по дефолту
                this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs][0];
              }
            } /* else if (crumbs == this.role) {
              //* если в урле ничего не передается берем по роли
              this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs][0];
            } */ else {
              //* собираем крошку НЕ переданной из урла и формируем ее на основе Переданной крошки, и остальные так же
              const selectedCrumbsBefore = this.selectedCrumbs[crumbsBeforeRole[index - 1]];
              const selectedCrumbsBeforeCode = selectedCrumbsBefore?.[getPropertyByEntity[crumbs]];
              //* бывает так когда нет у крошки ключа по которой можно ее найти (company не имеет связки с division)
              if (selectedCrumbsBeforeCode) {
                this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs].find(crumb => crumb.code === selectedCrumbsBeforeCode);
              } else {
                this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs][0];
              }
            }
          } else {
            console.warn('crumbsValues with "crumbs" is not exist');
          }
        });
      } else {
        this.snackbarStore.showInfo('Вам недоступен просмотр этого уровня.');
        console.warn('Вам недоступен просмотр этого уровня');
      }
      this.enabledCrumbs = IndicatorsRoleEmployeeTypes.slice(roleIndex, modeParamIndex + 1);
      const nextMode = IndicatorsRoleEmployeeTypes[modeParamIndex + 1];
      if (nextMode && this.crumbsValues[nextMode].length >= 1) {
        if (this.crumbsValues[nextMode].length === 1) {
          this.selectedCrumbs[nextMode] = this.crumbsValues[nextMode][0];
        }
        this.enabledCrumbs.push(nextMode);
      }
    }
  }
  setCrumbsValues(data: StatReferencesResponse): void {
    this.crumbsValues[INDICATORS_ROLE_EMPLOYEE_CODES.company] = data.headCompanies || [];
    this.crumbsValues[INDICATORS_ROLE_EMPLOYEE_CODES.division] = data.divisions || [];
    this.crumbsValues[INDICATORS_ROLE_EMPLOYEE_CODES.office] = [...(data.offices || [])].map(i => ({ ...i, title: i.name }));
    this.crumbsValues[INDICATORS_ROLE_EMPLOYEE_CODES.department] = data.departments || [];
    this.crumbsValues[INDICATORS_ROLE_EMPLOYEE_CODES.employee] = [...(data.employees || [])].map(employee => ({
      code: employee.code,
      title: `${employee.surname || ''} ${employee.name || ''} ${employee.patronymic || ''}`,
      departmentCode: employee.departmentCode,
    }));
  }
}
